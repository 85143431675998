import React from "react";
import { Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { GetYardOwnerList } from "../../services/YardOwnerService";
import { CustomNoRowsOverlay } from "../../components/TableNoRowsOvlery";
import Edit from "@mui/icons-material/Edit";
import { setModelToYardOwner } from "../../models/YardOwnerModel";
import clsx from "clsx";
import Cookies from "universal-cookie";
import AproveYardOwnerDialog from "../Modals/EditAgreementModal/AproveYardOwnerDialog";
import { useSnackBar } from "../../context/SnackBarContext";
import "./YardOwnerTable.css";
const YardOwnerTable = () => {
  const [yardOwners, setYardOwnerList] = React.useState({
    allRows: [],
    rows: [],
  });
  const { handleRenderSnackBar } = useSnackBar();
  const cookies = new Cookies();
  const [fetchingData, setFetchingData] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    show: false,
    row: {},
    handleFunction: (e) => handleCloseModal(),
  });
  function handleAproveYardOwner(row) {
    return function (e) {
      setModalInfo({
        show: true,
        row: row,
        handleFunction: (e) => handleCloseModal(),
      });
    };
  }

  const allColumns = [
    {
      field: "actions",
      type: "actions",
      minWidth: 90,
      align: "center",
      getActions: (params) => {
        if (params.row.status.toLowerCase() === "created") {
          return [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={handleAproveYardOwner(params)}
            />,
          ];
        }
        return [];
      },
    },

    {
      field: "companyName",
      headerName: "Company",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scacCode",
      headerName: "SCAC Code",
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      cellClassName: (params) => {
        const status = params.value.toLowerCase();
        return clsx({
          "YardOwnerList-YardOwnerTable-status-column-active":
            status === "approved",
          "YardOwnerList-YardOwnerTable-status-column-not-active":
            status === "created",
        });
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  function handleCloseModal() {
    setModalInfo((modalInfo) => ({
      show: false,
      row: {},
      handleFunction: (e) => handleCloseModal(),
    }));
  }

  function handleFetchData() {
    setFetchingData(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    if (!sessionCosmos) {
      window.addEventListener("userInB2C", () => {
        handleFetchData();
      });
      return;
    }
    GetYardOwnerList(sessionCosmos.scac_code).then((data) => {
      const yardOwmerList = setModelToYardOwner(data);
      setYardOwnerList({
        allRows: yardOwmerList,
        rows: yardOwmerList,
      });
      setFetchingData(false);
    });
    // getAgreements("SNEA").then((data) => {
    //   const agreementList = setModelToAgreements(data);
    //   setYardOwnerList({
    //     allRows: agreementList,
    //     rows: agreementList,
    //   });
    //   setFetchingData(false);
    // });
  }

  React.useEffect(() => {
    handleFetchData();
  }, []);

  const getRowId = (row) => row.idYardOwner;
  return (
    <>
      <section className="YardOwnerList-YardOwnerTable-container">
        <Typography
          variant="h5"
          className="YardOwnerList-YardOwnerTable-table-title"
        >
          Yard Owner List
        </Typography>
        <div className="YardOwnerList-YardOwnerTable-table-class">
          <DataGrid
            className="YardOwnerList-YardOwnerTable-table-class"
            getRowId={getRowId}
            rows={yardOwners.rows || []}
            columns={allColumns}
            disableSelectionOnClick
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,

              toolbar: GridToolbar,
            }}
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            loading={fetchingData}
          />
        </div>
        <AproveYardOwnerDialog
          openDeleteModal={modalInfo.show}
          yardOwner={modalInfo.row}
          onClose={handleCloseModal}
          fetchData={handleFetchData}
        />
      </section>
    </>
  );
};

export default YardOwnerTable;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  Backdrop,
  CircularProgress,
  IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import "./AproveYardOwnerDialog.css";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Cookies from "universal-cookie";
import { AproveYardOwner } from "../../../services/YardOwnerService";
import { useSnackBar } from "../../../context/SnackBarContext";
import dayjs from "dayjs";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
const AproveYardOwnerDialog = ({
  openDeleteModal,
  onClose,
  yardOwner,
  fetchData,
}) => {
  const cookies = new Cookies();
  const [saving, setSaving] = React.useState(false);
  const { handleRenderSnackBar } = useSnackBar();
  const [startDate, setStartDate] = React.useState(dayjs(new Date()));
  const [approvedState, setApprovedState] = React.useState({
    state: false,
    url: "",
  });
  const formatStartDate = (date) => {
    return dayjs(date).format("MM-DD-YYYY");
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(approvedState.url);
    handleRenderSnackBar("URL copied to clipboard", "success");
  };
  function handleAproveYardOwner() {
    const sessionCosmos = cookies.get("sessionCosmos");

    setSaving(true);
    let payload = {
      payload: {
        YardOwner: {
          ScacCode: row.scacCode,
          Id: row.idYardOwner,
        },
        Contract: {
          User: sessionCosmos.email[0],
          StartDate: formatStartDate(startDate),
        },
      },
    };
    AproveYardOwner(payload)
      .then((response) => {
        let customUrl =
          window.location.origin +
          "/landing-page/custom-agreement?YardOwnerScacCode=" +
          response.ScacCode;
        setApprovedState({
          state: true,
          url: customUrl,
        });
        fetchData();
        handleRenderSnackBar("Yard Owner Aproved", "success");
        setSaving(false);
      })
      .catch((error) => {
        handleRenderSnackBar("Error Aproving Yard Owner", "error");
        setSaving(false);
      });
  }
  [];
  let row = yardOwner?.row;
  function handleCloseModal() {
    row = null;
    setApprovedState({
      state: false,
      url: "",
    });
    onClose();
  }

  return (
    <>
      {" "}
      <Dialog open={openDeleteModal} onClose={handleCloseModal}>
        {saving ? (
          <>
            <Backdrop
              className="YardOwnerList-AproveDialog--backdrop"
              open={saving}
            >
              <CircularProgress
                thickness={5}
                className="YardOwnerList-AproveDialog--Circular-Progress"
              />
            </Backdrop>
          </>
        ) : (
          <>
            {approvedState.state ? (
              <>
                <DialogTitle>Yard Owner Approved</DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <Typography>
                      Yard Owner has been approved successfully. You can access
                      the custom agreement using the following URL:
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Typography className="YardOwnerList-AproveDialog-url-text">
                          {approvedState.url
                            ? approvedState.url
                            : "No URL available"}
                        </Typography>
                      </Grid>
                      <Grid xs={2} sm={2} md={2} lg={2} xl={2}>
                        <IconButton
                          aria-label="copy"
                          onClick={handleCopy}
                          className="YardOwnerList-AproveDialog-copy-icon"
                        >
                          <ContentPasteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
              </>
            ) : (
              <>
                <DialogTitle>Approve Yard Owner</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure to approve this yard owner?
                    <List>
                      {row ? (
                        <>
                          <ListItem>
                            <Typography className="YardOwnerList-AproveYardOwnerDialog-title">
                              Yard Owner Id:
                              <Typography className="YardOwnerList-AproveYardOwnerDialog-description">
                                {row.idYardOwner}
                              </Typography>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className="YardOwnerList-AproveYardOwnerDialog-title YardOwnerList-AproveYardOwnerDialog-title-display-flex">
                              Company Name:
                              <Typography className="YardOwnerList-AproveYardOwnerDialog-description YardOwnerList-AproveYardOwnerDialog-description-margin">
                                {row.companyName}
                              </Typography>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className="YardOwnerList-AproveYardOwnerDialog-title YardOwnerList-AproveYardOwnerDialog-title-display-flex">
                              SCAC Code:
                              <Typography className="YardOwnerList-AproveYardOwnerDialog-description YardOwnerList-AproveYardOwnerDialog-description-margin">
                                {row.scacCode}
                              </Typography>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className="YardOwnerList-AproveYardOwnerDialog-title YardOwnerList-AproveYardOwnerDialog-title-display-flex">
                              Status:
                              <Typography className="YardOwnerList-AproveYardOwnerDialog-description YardOwnerList-AproveYardOwnerDialog-description-margin">
                                {row.status}
                              </Typography>
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className="YardOwnerList-AproveYardOwnerDialog-title YardOwnerList-AproveYardOwnerDialog-title-display-flex YardOwnerList-AproveYardOwnerDialog-grid-start-date">
                              Start Date:
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                  label="Date"
                                  value={startDate}
                                  minDate={dayjs(new Date())}
                                  onChange={(newValue) => {
                                    setStartDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </Typography>
                          </ListItem>
                        </>
                      ) : (
                        <Typography>No yard Owner data available.</Typography>
                      )}
                    </List>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleAproveYardOwner}
                    color="primary"
                    autoFocus
                  >
                    Approve
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default AproveYardOwnerDialog;

import axios from "axios";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const GetYardOwnerList = async (ScacCodeUser) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_YARD_OWNER_LIST_BY_SCAC,
      method: "GET",
      params: {
        ScacCodeUser,
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const AproveYardOwner = async (yardOwner) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_APROVE_YARD_OWNER,
      method: "POST",
      data: yardOwner,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

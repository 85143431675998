// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .YardOwnerList-AproveYardOwnerDialog-title {
    font-weight: bold;
    color: #1578fc;
}

body .YardOwnerList-AproveYardOwnerDialog-description {
    font-weight: 500;
    color: #4a4a4a;
}

body .YardOwnerList-AproveYardOwnerDialog-title-display-flex {
    display: flex;

}

body .YardOwnerList-AproveYardOwnerDialog-description-margin {
    margin-left: 10px;
}

body .YardOwnerList-AproveYardOwnerDialog-grid-start-date {
    display: flex;
    align-items: center !important;
}

body .YardOwnerList-AproveDialog-url-text {
    font-weight: 500;
    color: #1578fc;
}

body .YardOwnerList-AproveDialog-copy-icon:hover {
    cursor: pointer;
    color: #1578fc;
    scale: 1.05;
    transition: all 0.3s;
}

body .YardOwnerList-AproveDialog--Circular-Progress {
    color: #fc4818;
    scale: 4;
    z-index: 99999999999;
}

body .YardOwnerList-AproveDialog-backdrop-props {
    backdrop-filter: blur(15px);
    z-index: 99999999999;
    background-color: rgba(34, 34, 79, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/containers/Modals/EditAgreementModal/AproveYardOwnerDialog.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;;AAEjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,QAAQ;IACR,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,oBAAoB;IACpB,uCAAuC;AAC3C","sourcesContent":["body .YardOwnerList-AproveYardOwnerDialog-title {\n    font-weight: bold;\n    color: #1578fc;\n}\n\nbody .YardOwnerList-AproveYardOwnerDialog-description {\n    font-weight: 500;\n    color: #4a4a4a;\n}\n\nbody .YardOwnerList-AproveYardOwnerDialog-title-display-flex {\n    display: flex;\n\n}\n\nbody .YardOwnerList-AproveYardOwnerDialog-description-margin {\n    margin-left: 10px;\n}\n\nbody .YardOwnerList-AproveYardOwnerDialog-grid-start-date {\n    display: flex;\n    align-items: center !important;\n}\n\nbody .YardOwnerList-AproveDialog-url-text {\n    font-weight: 500;\n    color: #1578fc;\n}\n\nbody .YardOwnerList-AproveDialog-copy-icon:hover {\n    cursor: pointer;\n    color: #1578fc;\n    scale: 1.05;\n    transition: all 0.3s;\n}\n\nbody .YardOwnerList-AproveDialog--Circular-Progress {\n    color: #fc4818;\n    scale: 4;\n    z-index: 99999999999;\n}\n\nbody .YardOwnerList-AproveDialog-backdrop-props {\n    backdrop-filter: blur(15px);\n    z-index: 99999999999;\n    background-color: rgba(34, 34, 79, 0.3);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

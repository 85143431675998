function getInactiveDays(startDate, dateTo) {
  if (!dateTo) return "-- N/A --";

  const oneDay = 24 * 60 * 60 * 1000; // milisegundos en un día
  return Math.round((new Date(dateTo) - new Date(startDate)) / oneDay);
}

function returnFormattedDate(date) {
  //Aug 1, 2023
  if (!date) return "N/A";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
}
function getOveragePrice(OverageObject) {
  if (!OverageObject[0]) return null;
  return OverageObject[0].Cost;
}
function formatCurrency(value) {
  if (!value) return "-- N/A --";
  return `$${value.toFixed(2)}`;
}
export function setModelToYardOwner(yardOwnerList) {
  return yardOwnerList.map((yardOwner) => {
    return {
      companyName: yardOwner.CompanyName ? yardOwner.CompanyName : "-- N/A --",
      scacCode: yardOwner.ScacCode ? yardOwner.ScacCode : "-- N/A --",
      status: yardOwner.Status ? yardOwner.Status : "-- N/A --",
      idYardOwner: yardOwner.idYardOwner ? yardOwner.idYardOwner : "-- N/A --",
    };
  });
}
